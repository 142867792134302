<template>
<v-card elevation="0" class="pa-2 mx-10" min-height="240" max-height="240">
    <v-card-title>PLAYER SETTINGS</v-card-title>
    <v-row>
        <v-col cols="0" sm="0" md="0" class="pl-0">
            <v-card
                    min-width="450"
                    max-width="450"
                    class="pa-4 my-0"
                    elevation="0"
                    outlined
            >
                <div class="font-weight-black">Delete by player id from {{this.$store.state.ugsSetting.environmentName}}</div>
                <v-row>
                    <v-col class="pt-2">
                        <v-card max-width="300"
                                elevation="0">
                            <v-text-field
                                    label="Player id"
                                    v-model="playerIdDelete"
                                    @input="onInputPlayerIdDelete"
                            />
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="pt-0">
                        <v-btn
                                @click="onDeletePlayerById"
                        >
                            Delete
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
<!--        <v-col cols="0" sm="0" md="0" class="pl-3">-->
<!--            <v-card-->
<!--                    min-width="230"-->
<!--                    max-width="230"-->
<!--                    class="pa-4 my-0"-->
<!--                    elevation="0"-->
<!--                    outlined-->
<!--            >-->
<!--                <div class="font-weight-black">Delete player in counter</div>-->
<!--                <v-row>-->
<!--                    <v-col class="pt-2">-->
<!--                        <v-card max-width="100"-->
<!--                                elevation="0">-->
<!--                            <v-text-field-->
<!--                                    label="Player counter"-->
<!--                                    v-model="playerDeleteCounter"-->
<!--                                    type="number"-->
<!--                                    :min="1"-->
<!--                                    @input="onInputPlayerCounterDelete"-->
<!--                            />-->
<!--                        </v-card>-->
<!--                    </v-col>-->
<!--                </v-row>-->
<!--                <v-row>-->
<!--                    <v-col class="pt-0">-->
<!--                        <v-btn-->
<!--                                @click="onDeletePlayerByCounter"-->
<!--                        >-->
<!--                            Delete-->
<!--                        </v-btn>-->
<!--                    </v-col>-->
<!--                </v-row>-->
<!--            </v-card>-->
<!--        </v-col>-->
        <v-col cols="0" sm="0" md="0" class="pl-3">
            <v-card
                    min-height="180"
                    max-height="180"
                    class="pa-4 my-0"
                    elevation="0"
                    outlined
            >
                <div class="font-weight-black pb-0 mb-3">Delete players by environment</div>
                <v-row>
                    <v-col class="pt-0">
                        <v-card min-width="220"
                                max-width="220"
                                elevation="0">
                            <v-combobox
                                    v-model="environmentDeleteName"
                                    :items="environmentRef"
                                    label="Environment Name"
                                    prepend-icon="mdi-filter-variant"
                                    solo
                            >
                            </v-combobox>
                        </v-card>
                    </v-col>
                    <v-col class="pt-0">
                        <v-card max-width="250"
                                elevation="0">
                            <v-text-field
                                    label="Limit Player for check to delete"
                                    v-model="playerEnvDeleteCounter"
                                    type="number"
                                    :min="1"
                            />
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="pt-0">
                        <v-btn
                                @click="onDeletePlayerByEnvironmentId"
                        >
                            Delete from {{environmentDeleteName}}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
    </v-row>

    <v-overlay :value="isLoading">
        <v-progress-circular
                indeterminate
                size="64"
        ></v-progress-circular>
    </v-overlay>

    <PopupMessage v-if="messageAlert!==''"
                  :pColor="messageColor"
                  @onClose="messageAlert=''"
    >
        {{messageAlert}}
    </PopupMessage>
</v-card>
</template>

<script>

    import PopupMessage from "@/item-editor/modules/PopupMessage/PopupMessage";
    import UnityServiceApi from '../api/UnityServiceApi';
    import {GlobalEvents, EVENT_UPDATED_EVIRONMENT_CONFIG} from "@/item-editor/events/GlobalEvents";

    export default {
        name: "UnityPlayerConfig",
        props:[],
        components: {
            PopupMessage,
        },
        created() {
            this.initEnvironmentRef();
            GlobalEvents.$on(EVENT_UPDATED_EVIRONMENT_CONFIG, () => this.initEnvironmentRef());
        },
        data() {
            return {
                isLoading: false,
                messageAlert: '',
                messageColor: 'orange',
                playerIdDelete: '',
                playerDeleteCounter: 100,
                environmentDeleteName: '',
                environmentRef: [],
                playerEnvDeleteCounter: 10,
            }
        },
        methods: {
            initEnvironmentRef(){
                let envList = this.$store.state.ugsSetting.environmentList;
                this.environmentRef = [];
                envList.forEach(e => {
                    this.environmentRef.push(e.name);
                });
                this.environmentDeleteName = this.$store.state.ugsSetting.environmentName;
            },
            onInputPlayerIdDelete(){

            },
            onDeletePlayerById(){
                if(this.playerIdDelete === ''){
                    this.setAlertMessage('Invalid player id for delete', true);
                } else {
                    if(confirm(`Do you want to delete player id ${this.playerIdDelete}?`)){
                        this.setWaitingAndLoading(true);
                        UnityServiceApi.deletePlayerById(this.playerIdDelete, this.$store.state.ugsSetting.environmentId)
                            .then(result => {
                                console.log("Result : ", result);
                                this.setAlertMessage('Success delete player '+this.playerIdDelete, false);
                                this.playerIdDelete = '';
                                setTimeout(() => {
                                    this.setWaitingAndLoading(false);
                                }, 1500);

                            })
                            .catch(error => {
                                console.log("error : ", error);
                                this.setAlertMessage('Failed to delete player by id', true);
                                setTimeout(() => {
                                    this.isLoading = false;
                                }, 200);
                            });
                    }
                }
            },
            onInputPlayerCounterDelete(){

            },
            onDeletePlayerByCounter(){
                if(this.playerDeleteCounter === '' || this.playerDeleteCounter <= 0){
                    this.setAlertMessage('Invalid player counter for delete', true);
                } else {
                    this.setWaitingAndLoading(true);
                    UnityServiceApi.deletePlayerByCounter(this.playerDeleteCounter)
                        .then(result => {
                            console.log("Result : ", result);
                            this.setAlertMessage('Success delete by counter', false);
                            setTimeout(() => {
                                this.setWaitingAndLoading(false);
                            }, 1500);

                        })
                        .catch(error => {
                            console.log("Error : ", error);
                            this.setAlertMessage('Failed to delete player by counter', true);
                            setTimeout(() => {
                                this.isLoading = false;
                            }, 200);
                        });
                }
            },
            onDeletePlayerByEnvironmentId(){
                if(this.environmentDeleteName === ''){
                    this.setAlertMessage('Invalid environment id for player delete', true);
                } else if(this.playerEnvDeleteCounter <= 0){
                    this.setAlertMessage('Invalid player counter for delete', true);
                } else {
                    this.setWaitingAndLoading(true);
                    let envList = this.$store.state.ugsSetting.environmentList;
                    let ref = envList.find(r => r.name === this.environmentDeleteName);
                    if(ref){
                        if(confirm(`Do you want to delete player id total ${this.playerEnvDeleteCounter} from ${this.environmentDeleteName}?`)){
                            UnityServiceApi.deletePlayerByEnvironmentId(ref.id, this.playerEnvDeleteCounter)
                                .then(result => {
                                    console.log("Result : ", result);
                                    this.setAlertMessage(`Success delete ${result.DeleteSuccessful} on env ${this.environmentDeleteName} of ${result.Total} players`, false);
                                    this.playerIdDelete = '';
                                    setTimeout(() => {
                                        this.setWaitingAndLoading(false);
                                    }, 1500);

                                })
                                .catch(error => {
                                    console.log("Error : ", error);
                                    this.setAlertMessage('Failed to delete player by environment', true);
                                    setTimeout(() => {
                                        this.isLoading = false;
                                    }, 200);
                                });
                        }
                    } else {
                        this.setAlertMessage('Invalid environment id', true);
                    }
                }
            },

            setWaitingAndLoading(waiting){
                this.isLoading = waiting;
            },
            setAlertMessage(msg, isError){
                this.messageAlert = msg;
                if(isError){
                    this.messageColor = "red";
                } else {
                    this.messageColor = "success";
                }
            }
        }
    }
</script>

<style scoped>

</style>